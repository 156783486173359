<template>
	<w-layout fill-height>
		<w-layout v-if="wizard || application.wizard" fill-height pa-5>
			<MobileAppCreationWizard v-model="application" @back="cancel" @build="create" @deploy="deploy" @end="cancel" />
		</w-layout>
		<w-layout v-else column fill-height pa-5 scroll-y>
			<w-flex shrink>
				<w-layout row>
					<w-flex shrink>
						<w-layout column justify-center align-center>
							<MobileAppIcon v-model="application" />
							<MobileAppColors v-model="application" />
						</w-layout>
					</w-flex>
					<w-flex>
						<v-layout column>
							<w-flex>
								<w-layout row>
									<w-flex>
										<MobileAppTitle v-model="application" @input="updateValues" />
									</w-flex>
									<w-flex ml-2>
										<MobileAppMarketingURL v-model="application" />
									</w-flex>
								</w-layout>
							</w-flex>
							<MobileAppSubtitle v-model="application" @input="updateValues" />
							<MobileAppSlogan v-model="application" @input="updateValues" />
							<w-flex>
								<w-layout row>
									<w-flex xs6>
										<MobileAppDomains v-model="application" />
									</w-flex>
									<w-flex ml-2>
										<MobileAppKeywords v-model="application" />
									</w-flex>
								</w-layout>
							</w-flex>
						</v-layout>
					</w-flex>
				</w-layout>
			</w-flex>
			<w-flex grow>
				<MobileAppDescription v-model="application" />
			</w-flex>
			<w-flex v-if="application.id" shrink>
				<w-layout row justify-space-between align-center>
					<w-flex xs5>
						<w-layout column>
							<MobileAppBundleID v-model="application" :can-edit="false" />
							<MobileAppPlayStoreLink v-model="application" />
						</w-layout>
					</w-flex>
					<w-flex xs5>
						<w-layout column>
							<MobileAppAppStoreID v-model="application" :can-edit="false" />
							<MobileAppAppStoreLink v-model="application" />
						</w-layout>
					</w-flex>
					<w-flex xs1>
						<MobileAppGitlabBranchLink v-model="application" />
					</w-flex>
				</w-layout>
			</w-flex>
			<w-flex shrink>
				<w-layout row justify-center align-center>
					<w-flex shrink>
						<v-btn round color="primary" @click="cancel">
							<v-icon>cancel</v-icon>
							<span v-t="modified ? 'actions.cancel' : 'actions.go_back'" class="ml-2"></span>
						</v-btn>
					</w-flex>
					<w-flex v-if="application.id" shrink>
						<v-btn :disabled="syncing" round color="red" @click="deleteMobileApp">
							<v-icon>delete</v-icon>
							<span v-t="'actions.delete'" class="ml-2"></span>
						</v-btn>
					</w-flex>
					<w-flex shrink>
						<v-btn :disabled="syncing || !canUpdate" round color="primary" @click="saveMobileApp">
							<v-icon>save</v-icon>
							<span v-if="application.id" v-t="'actions.update'" class="ml-2"></span>
							<span v-else v-t="'actions.create'" :loading="loading" class="ml-2"></span>
						</v-btn>
					</w-flex>
					<w-flex shrink>
						<v-btn v-if="application.id" :disabled="syncing || !canUpgrade" round color="primary" @click="upgradeMobileApp">
							<v-icon>upgrade</v-icon>
							<span v-if="application.id" v-t="'mobileapp.actions.upgrade'" class="ml-2"></span>
						</v-btn>
					</w-flex>
					<w-flex shrink>
						<v-btn v-if="application.id" :disabled="syncing || !canUpdate || !canUpgrade" round color="primary" @click="upgradeAndUpdateMobileApp">
							<v-icon>upgrade</v-icon>
							<span v-if="application.id" v-t="'mobileapp.actions.upgrade_and_update'" class="ml-2"></span>
						</v-btn>
					</w-flex>
				</w-layout>
			</w-flex>
		</w-layout>
		<w-dialog v-model="deleteDialog" error max-width="66vh" :title="$t('mobileapp.actions.delete')">
			<w-layout column justify-center align-center fill-height>
				<w-flex grow>
					<w-layout row justify-center align-center fill-height>
						<MobileAppStepDelete v-model="application" step-name="delete" @done="doDelete" />
					</w-layout>
				</w-flex>
			</w-layout>
		</w-dialog>
	</w-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

function sanitizeTitle(string) {
	return string
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '') // accents
		.replace(/[^a-z0-9.]/g, '.')
		.replace(/\.{2,}/g, '.') // remove multiple dots
		.replace(/^\d+/, '') // remove digits at the beginning of the string
		.replace(/^\./, '') // Remove dot if it's the first character
		.replace(/\.$/, '') // Remove dot if it's the last character
}

export default {
	name: 'MobileAppEditor',
	components: {
		MobileAppAppStoreID: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppAppStoreID')
		}),
		MobileAppAppStoreLink: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppAppStoreLink')
		}),
		MobileAppBundleID: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppBundleID')
		}),
		MobileAppColors: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppColors')
		}),
		MobileAppDescription: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppDescription')
		}),
		MobileAppDomains: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppDomains')
		}),
		MobileAppGitlabBranchLink: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppGitlabBranchLink')
		}),
		MobileAppIcon: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppIcon')
		}),
		MobileAppKeywords: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppKeywords')
		}),
		MobileAppMarketingURL: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppMarketingURL')
		}),
		MobileAppCreationWizard: () => ({
			component: import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppCreationWizard')
		}),
		MobileAppPlayStoreLink: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppPlayStoreLink')
		}),
		MobileAppStepDelete: () => ({
			component: import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppStepDelete')
		}),
		MobileAppTitle: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppTitle')
		}),
		MobileAppSubtitle: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppSubtitle')
		}),
		MobileAppSlogan: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppSlogan')
		})
	},
	mixins: [SuperAdminModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		},
		loading: {
			required: false,
			type: Boolean,
			default: false
		},
		syncing: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			wizard: false,
			initial_application: null,
			application: null,
			deleteDialog: false
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		modified: function () {
			let result = false
			if (JSON.stringify(this.application) != JSON.stringify(this.initial_application)) {
				result = true
			}
			return result
		},
		validated: function () {
			let result = true
			if (
				!this.application.logo_file_path.trim().length ||
				!this.application.color.trim().match(/^#[0-9a-fA-F]{6}$/) ||
				!this.application.title.trim().length ||
				this.application.title.length > 31 ||
				!this.application.subtitle.trim().length ||
				this.application.subtitle.length > 31 ||
				!this.application.promotional_text.trim().length ||
				this.application.promotional_text.length > 81 ||
				!this.application.accounting_firms ||
				!this.application.description.trim().length ||
				this.application.description.length > 4001 ||
				!this.application.keywords.trim().length ||
				this.application.keywords.length > 101 ||
				!this.application.marketing_url.length ||
				!this.validateUrl(this.application.marketing_url) ||
				this.application.marketing_url.length > 2049 ||
				this.application.title === this.application.subtitle ||
				this.application.title === this.application.promotional_text
			) {
				result = false
			}
			return result
		},
		canUpdate: function () {
			return this.modified && this.validated
		},
		canUpgrade: function () {
			return this.application.is_upgradable
		}
	},
	watch: {
		value: {
			immediate: true,
			handler: function () {
				this.application = {
					logo: null,
					logo_file_path: '',
					color: '',
					title: '',
					subtitle: '',
					promotional_text: '',
					description: this.$t('mobileapp.defaults.description'),
					keywords: this.$t('mobileapp.defaults.keywords'),
					accounting_firms: [],
					bundle_id: '',
					app_store_id: '',
					app_store_url: '',
					marketing_url: 'https://www.welyb.fr',
					support_url: 'https://support.welyb.fr',
					play_store_url: ''
				}
				this.initial_application = {
					logo: null,
					logo_file_path: '',
					color: '',
					title: '',
					subtitle: '',
					promotional_text: '',
					description: this.$t('mobileapp.defaults.description'),
					keywords: this.$t('mobileapp.defaults.keywords'),
					accounting_firms: [],
					bundle_id: '',
					app_store_id: '',
					app_store_url: '',
					marketing_url: 'https://www.welyb.fr',
					support_url: 'https://support.welyb.fr',
					play_store_url: ''
				}
				Object.assign(this.application, this.value)
				Object.assign(this.initial_application, this.value)
			}
		},
		application: {
			deep: true,
			immediate: true,
			handler: function () {
				if ([null, ''].includes(this.application.google_play_store_url) && this.application?.bundle_id?.trim().length > 0) {
					this.application.google_play_store_url = `https://play.google.com/store/apps/details?id=${this.application.bundle_id}`
				}
				if ([null, ''].includes(this.application.app_store_url) && this.application?.app_store_id != null && this.application?.title?.trim().length > 0) {
					const appNameSnakeCase = this.application.title.toLowerCase().replace(' ', '-')
					this.application.app_store_url = `https://apps.apple.com/us/app/${appNameSnakeCase}/id${this.application.app_store_id}`
				}
			}
		}
	},
	methods: {
		cancel: function () {
			this.wizard = false
			this.eventBus.emit(this.events.CLEAR_SELECTED_MOBILE_APP)
			this.eventBus.emit(this.events.LOAD_MOBILE_APP_LIST)
		},
		saveMobileApp: async function () {
			if (!this.application.id) {
				let packageName = ''
				if (this.application.accounting_firms?.length) {
					const domainRegExp = new RegExp('.' + this.$envVar.VUE_APP_BASE_DOMAIN.replace('.', '\\.'), 'i')
					packageName = this.application.accounting_firms[0].domains
						.filter(domain => domainRegExp.test(domain.url))[0]
						.url.replace('.' + this.$envVar.VUE_APP_BASE_DOMAIN, '')
						.split('.')
						.reverse()
						.join('.')
						.replaceAll('-', '.')
				} else {
					packageName = sanitizeTitle(this.application.title)
				}
				// eslint-disable-next-line quotes
				packageName = packageName?.trim().replace("'", '&#39;')
				if (/^\d/.test(packageName)) {
					packageName = 'cab' + packageName
				}
				this.application.bundle_id = 'fr.welyb.' + packageName
				this.application.description = this.application.description.replace(/%AppName%/g, this.application.title)
				this.service
					.createMobileAppWizard(this.application)
					.then(wizard => {
						this.application.id = wizard.mobile_app_id
					})
					.then(() => {
						this.wizard = true
					})
			} else {
				let application = await this.update()
				Object.assign(this.application, application)
			}
		},
		create: function () {
			this.model = this.application
			return this.eventBus.emit(this.events.CREATE_MOBILE_APP)
		},
		deploy: function () {
			this.application.secondBuild = true
			this.update()
		},
		update: function () {
			this.model = this.application
			return this.eventBus.emit(this.events.UPDATE_MOBILE_APP)
		},
		upgradeMobileApp: function () {
			this.eventBus.emit(this.events.UPGRADE_MOBILE_APP, this.model.id)
		},
		upgradeAndUpdateMobileApp: function () {
			this.eventBus.emit(this.events.UPGRADE_AND_UPDATE_MOBILE_APP, this.model.id)
		},
		deleteMobileApp: function () {
			this.deleteDialog = true
		},
		doDelete: function () {
			this.eventBus.emit(this.events.DELETE_MOBILE_APP, this.model.id)
			this.deleteDialog = false
			this.cancel()
		},
		validateUrl: function (url) {
			const pattern = /^http[s]{0,1}[:][/]{2}[a-zA-Z0-9-_.~?&#:]+$/
			return pattern.test(url)
		},
		updateValues: function () {
			const saveTitle = this.application.title
			const saveSubTitle = this.application.subtitle
			const savePromotionalText = this.application.promotional_text
			this.application.title = 'app title'
			this.application.subtitle = 'app subtitle'
			this.application.promotional_text = 'app promotional text'
			this.$nextTick(() => {
				this.application.title = saveTitle
				this.application.subtitle = saveSubTitle
				this.application.promotional_text = savePromotionalText
			})
		}
	}
}
</script>

<style>
.app-logo-icon-uploader {
	border: 3px dashed;
	border-radius: 20px !important;
}
.app-logo-icon-uploader .v-image__image {
	border-radius: 20px !important;
}
.layout {
	border: red !important;
}
textarea {
	overflow-y: scroll;
	resize: none;
	height: 100% !important;
}
</style>
